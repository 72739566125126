function Agreement() {
  return (
    <>
<p class="p1"><b>제 1 조 (목적)</b></p>
<p class="p1"><b>이 약관은 누리네트워크가 제공하는 인터넷 서비스를 운영하는데 있어 회원가입에 대한 조건 및 절차와 기타 필요한 사항을 규정함을 목적으로 합니다.</b></p>
<p class="p1"><b>제 2 조 (약관의 효력 및 변경)</b></p>
<p class="p1"><b>① 이 약관의 내용은 회원의 동의와 공지에 의해서 효력을 발생합니다.</b></p>
<p class="p1"><b>② 본 사이트 운영위가 필요하다고 인정되는 경우 이 약관의 내용을 개정(변경, 삭제 또는 추가) 할 수 있으며, 개정된 약관은 상기 제①항과 같은 방법으로 효력을 발생합니다.</b></p>
<p class="p1"><b>제 3 조 (약관외 준칙)</b></p>
<p class="p1"><b>이 약관에 명시되지 않은 사항은 전기통신기본법, 전기 통신사업법 및 기타 관련법령의 규정에 의합니다.</b></p>
<p class="p1"><b>제 4 조 (서비스 이용 계약의 성립)</b></p>
<p class="p1"><b>① 이용계약은 이용고객의 본 이용약관 내용에 대한 동의와 이용신청에 따른 회사의 이용승낙으로 성립됩니다.</b></p>
<p class="p1"><b>② 회사는 아래사항에 해당하는 이용계약 신청에 대하여는 이를 승낙하지 아니 할 수 있습니다.</b></p>
<p class="p1"><b>* 실명이 아니거나 타인의 명의를 이용하여 신청한 경우</b></p>
<p class="p1"><b>* 이용계약 신청서의 내용을 허위로 기재한 경우</b></p>
<p class="p1"><b>* 사회의 질서, 미풍양속을 저해할 목적으로 신청한 경우</b></p>
<p class="p1"><b>* 부정한 용도로 본 서비스를 이용하고자 하는 경우</b></p>
<p class="p1"><b>* 기타 규정한 제반사항을 위반하며 신청하는 경우</b></p>
<p class="p1"><b>제 5 조 (서비스 이용 )</b></p>
<p class="p1"><b>① 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다.</b></p>
<p class="p1"><b>② 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 일시중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시중단은 사전에 공지합니다</b></p>
<p class="p1"><b>③ 회사는 회사가 통제할 수 없는 사유로 인한 서비스중단의 경우(시스템관리자의 고의, 과실 없는 디스크장애, 시스템다운 등)에 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의 고의,과실로 인한 시스템중단 등의 경우에는 통지하지 않습니다.</b></p>
<p class="p1"><b>④ 회사는 ‘기존상품에 종속된 하위상품’(이하 하위상품)을 이용자에게 유료 또는 무료로 제공할 수 있으며, 그 하위상품은 기존상품이 만료 또는 정지되어 서비스를 제공하지 않을 경우 하위상품의 서비스 제공도 종료 됩니다.</b></p>
<p class="p1"><b>⑤ 회사는 하위상품의 서비스가 종료 되어 사용이 정지되더라도 잔여 서비스를 제공하여야 할 의무가 없습니다</b></p>
<p class="p1"><b>⑥ 회사는 위 4항, 5항의 내용을 이용자에게 사전 고지를 하여야 하며, 그 방법은 홈페이지 상에 게시하는 방법으로 고지합니다</b></p>
<p class="p1"><b>제 6 조 (회사의 의무)</b></p>
<p class="p1"><b>① 회사는 특별한 사정이 없는 한 이용자가 신청한 서비스 제공 개시일에 서비스를 이용할수 있도록 합니다.</b></p>
<p class="p1"><b>② 회사는 이 약관에서 정한바에 따라 지속적, 안정적인 서비스를 제공할 의무가 있습니다.</b></p>
<p class="p1"><b>③ 회사는 서비스 제공의 목적으로 취득한 고객의 정보를 본인의 승낙없이 타인에게 누설, 배포하지 않으며 기밀을 유지해야 합니다. 다만, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 정보통신윤리위원회의 요청이 있는 경우 또는 회사가 정한 기간 동안 이용요금을 체납하여 이용고객에게 법적 절차에 따른 통보 후 신용정보사업자 또는 신용정보집중기관에 제공하는 경우에는 그러지 아니합니다.</b></p>
<p class="p1"><b>④ 회사는 이용자로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 하며, 그러지 아니한 경우 이용자에게 그 사유와 처리일정을 알려주어야 합니다.</b></p>
<p class="p1"><b>5. 회사가 제공한 서비스를 통하여 실행한 회원의 영업 활동 결과 및 손실에 관해서 회사는 책임을 지지 않습니다.</b></p>
<p class="p1"><b>제 7 조 (이용자의 의무)</b></p>
<p class="p1"><b>① 이용자는 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.</b></p>
<p class="p1"><b>② 이용자는 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 공지사항 등 회사가 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위를 해서는 안됩니다.</b></p>
<p class="p1"><b>③ 이용자은 주소, 연락처, 전자우편 주소 등 이용계약사항이 변경된 경우에 해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다.</b></p>
<p class="p1"><b>④ 회사가 관계법령 및 '개인정보 보호정책'에 의거하여 그 책임을 지는 경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 이용자에게 있습니다.</b></p>
<p class="p1"><b>제 8 조 (손해배상)</b></p>
<p class="p1"><b>① 회사는 서비스에서 제공하는 서비스의 이용과 관련하여 개인정보보호정책에서 정하는 내용에 해당하지 않는 사항에 대하여는 어떠한 손해도 책임을 지지 않습니다.</b></p>
<p class="p1"><b>② 서비스 구독주소의 관리책임은 이용자에게 있습니다. 이를 소홀히 관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 관한 책임은 이용자에게 있으며 회사는 그에 대해 아무런 책임이 없습니다.</b></p>
<p class="p1"><b>제 9 조 (면책조항)</b></p>
<p class="p1"><b>① 이용자가 서비스 이용중 입은 손해에 대해서 회사는 일체 책임을 지지 않습니다. 이용자는 자신이 제공하는 자료에 대하여 모든 책임을 집니다.</b></p>
<p class="p1"><b>② 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</b></p>
<p class="p1"><b>③ 회사는 천재지변, 국가비상사태 또는 이에 준하는 불가항력으로 인한 서비스중단에 대하여 책임을 지지 않습니다.</b></p>
</>
  )}
export default Agreement;
